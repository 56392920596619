.LockedChartPanel {
  $borderPad: 7px;
  position: absolute;
  top: $borderPad;
  left: $borderPad;
  bottom: $borderPad;
  right: $borderPad;

  .react-timeline {
    height: 45vh !important;
    margin-left: 48px;
  }

  .temp-buttons {
    position: absolute;
    top: $dims_padding;
    right: $dims_padding;

    button {
      background: none;
      border: 1px solid $color_bg-dark;
      border-radius: 5px;
      margin: $dims_padding;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
  }
}
