.SelectionInfo {
  position: fixed;
  top: 0;
  right: 0;

  width: 214px;

  pointer-events: none;

  .panel {
    margin-right: $panel-space;
    margin-top: $panel-space;
    padding: 0;

    font-weight: 400;

    border-radius: $box_border-radius;
    pointer-events: all;
    overflow: hidden;
  }

  .title-bar {
    align-items: center;
    overflow: visible;

    padding: $dims_padding;

    color: $color_light-blue;

    background-color: $color_lightest-blue;
  }

  .content {
    padding: $dims_padding;
  }

  .row {
    display: flex;

    margin-bottom: $dims_padding;

    .name {
      width: 8.571rem;
      padding: $dims_padding;;

      text-align: right;
      text-transform: uppercase;
    }

    .value {
      width: 100%;
      padding: $dims_padding;

      background-color: $color_lightest-blue;
      border-radius: $box_border-radius--medium;
    }
  }

  button {
      width: 100%;
      margin-top: $dims_padding-large;

      border: 1px solid $color_light-blue;
  }
}
