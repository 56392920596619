.ToolBar {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: ($dims_padding-large * 3) + $dims_button-size;
    left: $dims_padding-large;
    z-index: 1;

    max-width: 40px;
    padding: 0;

    button {
        &:first-of-type {
            border-radius: $box_border-radius--small $box_border-radius--small 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 $box_border-radius--small $box_border-radius--small;
        }

        &:hover {
            background: $color_bg-active;
            transition: background .2s;
        }
    }
}

