@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

$color_lightest-blue: #F2F5FA;
$color_light-blue: #6666FF;
$color_dark-blue: #050038;
$color_blue: #0000FF;
$color_neutral: #7D7A96;
$color_red: #AD0D2E;
$color_active: $color_light-blue;

$color_bg-active: $color_lightest-blue;
$color_bg-med-light: $color_bg-active;
$color_bg-light: #FFFFFF; //match to Settings.tsx
$color_bg-medium: #EDEBFF;
$color_bg-dark: $color_dark-blue;

$color_font-light: #FFFFFF;
$color_font-medium: $color_neutral;
$color_font-dark: $color_dark-blue;

$box_border-radius--small: 5px;
$box_border-radius--medium: 10px;
$box_border-radius--large: 20px;
$box_border-radius: $box_border-radius--small;
$box_shadow: 0 0 5px rgba($color_bg-dark, .4);

$font_size--base: 12px;
$font_size-xsmall: 0.714rem; // 10px
$font_size-small: 0.857rem;  // 12px
$font_size-regular: 1rem;    // 14px
$font_size-medium: 1.429rem; // 20px
$font_size-large: 1.714rem;  // 24px
$font_size-xlarge: 3.429rem; // 48px

$font_family: 'Inter', sans-serif;

$dims_button-size: 2.857rem; // 40px
$dims_padding: 0.714rem;     // 10px
$dims_padding-large: $dims_padding * 1.5; // 15px

$panel-space: $dims_padding-large;

@mixin no-bg {
  pointer-events: none;

  * {
    pointer-events: all;
  }
}

*, *:before, *:after {
  position: relative;
  box-sizing: inherit;
  line-height: 1.2em;
}

html, body {
  position: relative;
  margin: 0;
  padding: 0;
  color: $color_font-dark;
  font-family: $font_family;
  font-size: $font_size--base;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

button {
    padding: $dims_padding;

    background: $color_bg-light;
    border: none;
    outline: none;
    transition: background .2s;

    &.primary, &.secondary, &.warn {
        border-radius: $box_border-radius--medium;
        box-shadow: $box_shadow;
    }

    &.primary {
        color: $color_font-light;
        background: $color_light-blue;

        &:not(.disabled):hover {
            background: $color_blue;
        }
    }

    &.secondary {
        color: $color_light-blue;
        background: $color_bg-light;

        &:not(.disabled):hover {
            background: $color_bg-medium;
        }
    }

    &.warn {
        color: $color_font-light;
        background: $color_red;

        &:not(.disabled):hover {
            background: darken($color_red, 5);
        }
    }

    &.text {
        color: $color_font-medium;
        text-decoratioN: underline;
        transition: color .2s;

        &:not(.disabled):hover {
            color: $color_font-dark;
            transition: color .2s;
        }
    }

    &:not(.disabled):hover {
        cursor: pointer;

        .icon {
            stroke: $color_light-blue;
            transition: stroke .2s;
        }
    }

    &.disabled, &.disabled:hover {
        opacity: .6;
        cursor: normal;
        pointer-events: none;
    }

}

.icon {
    width: $font_size-medium * 1.25;
    height: $font_size-medium * 1.25;
    stroke: $color_font-dark;

    transition: stroke .2s;
}
