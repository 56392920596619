.Filters {
    border-bottom: 1px solid $color_bg-medium;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        display: inline-block;

        margin-bottom: $dims_padding;
        margin-right: $dims_padding;
        padding: 0 $dims_padding 0 0;

        color: $color_font-light;
        text-transform: uppercase;
        font-weight: 500;

        border-radius: $box_border-radius;
        cursor: pointer;
        opacity: 1;
        transition: opacity .15s;

        &:hover {
            opacity: .8;
            transition: opacity .15s;
        }

        input {
            margin-right: $dims_padding-large / 2;
        }
    }

}
