.ChartsHeader {
  //position: absolute;
  z-index: 1;
  $legendHeight: 20px;

  .title{
    flex-shrink: 0;
    overflow: visible;
    white-space: pre;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    color: #050038;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.2;
    border-bottom: 1px solid #F2F5FA;
    padding: 5px 10px;
  }
  .legendMask {
    height: $legendHeight + 9px;
    overflow: hidden;

    &:hover {
      overflow: visible;
    }
  }

  .Legend {
    display: block;
    width: 100%;

    .LegendItem {
      display: inline-block;
      height: $legendHeight;

      box-sizing: border-box;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 3px;
      padding: 3px;
      background-color: #edebff;
      overflow: visible;
      border-radius: 20px;


      .colorDiv {
        vertical-align: middle;
        display: inline-block;
        margin-left: 2px;
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        background-color: #57fac6;
        overflow: hidden;
        border-radius: 50px;
      }

      .label {
        vertical-align: middle;
        display: inline-block;
        margin: 0 2px;
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        color:  #050038;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 0.9;
        text-transform: uppercase;
        text-align: left;
      }
    }

  }
}
