.FileModal {

    .ReactModal {
        &__window {
            width: 90%;
            max-width: 900px;
        }
    }

    ul  {
        max-height: 60vh;
        padding: 0;

        list-style: none;
        overflow-y: auto;
    }

    h3 {
        margin-top: 0;
        font-size: $font_size-medium;
        font-weight: 500;
    }

    .current-file {
        .FileItem {
            border-top: 1px solid $color_bg-dark;
        }

        + h3 {
            margin-top: 3rem;
        }
    }

    .FileItem {
        display: flex;

        padding: $dims_padding-large;

        border-bottom: 1px solid $color_bg-dark;
    }

    li {
        background: $color_bg-light;
        cursor: pointer;
        transition: background .2s;

        &:first-of-type {
            border-top: 1px solid $color_bg-dark;
        }

        &:hover {
            background: $color_lightest-blue;
            transition: background .2s;
        }

        &.selected {
            background: $color_bg-medium;
            transition: background .3s;

            &:hover {
                background: darken($color_bg-medium, 2);
                transition: background .3s;
            }
        }
    }

    .project-info {
        flex: 1 0 auto;

        h4 {
            margin: 0 auto $dims_padding;
            font-size: $font_size-medium;
            font-weight: 500;
        }

        p {
            font-size: $font_size-regular;
            color: $color_font-medium;
            margin: 0;
        }
    }

    .project-details {
        display: flex;
        flex: 0 1 max-content;
        flex-direction: column;
        align-items: center;

        color: $color_font-medium;

        div {
            width: 100%;
            margin: auto;
            line-height: 1.5em;
            padding: $dims_padding / 2 $dims_padding;
            font-size: $font_size-regular;
            text-overflow: ellipsis;
            white-space: nowrap;

            background: $color_lightest-blue;
            border-radius: $box_border-radius--large;

            + div {
                margin-top: $dims_padding;
            }
        }

        span {
            vertical-align: middle;

            margin: auto;
            margin-right: $dims_padding;

            font-size: $font_size-xsmall;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    .project-actions {
        display: flex;

        margin-left: $dims_padding-large;

        button {
            display: inline-block;
            vertical-align: middle;

            margin: auto;

            box-shadow: none;
            background: none;
        }
    }

    .status {
        height: 150px;

        color: $color_font-medium;
        font-size: $font_size-regular;
        text-align: center;

        span {
            top: 60px;
            margin: auto;
            vertical-align: middle;
        }
    }

    .modal-buttons {
        margin-top: $dims_padding-large * 2;

        .create-fresh {
            margin-left: 0 !important;
        }
    }

}
