.RadioSlider {
    display: flex;
    vertical-align: middle;

    height: 100%;

    background: $color_bg-medium;
    border-radius: $box_border-radius;
    overflow: hidden;

    &__highlighter {
        z-index: 1;
        position: absolute;

        height: 100%;

        background: $color_light-blue;
        border-radius: $box_border-radius;

        transition: left .25s;
    }

    &__option {
        z-index: 2;
        flex: 1;

        margin: auto;

        color: $color_font-dark;
        font-size: $font_size-small;
        text-align: center;

        cursor: pointer;
        opacity: 1;
        transition: color .25s, opacity .25s;
        vertical-align: middle;

        &:hover {
            opacity: .75;
            transition: color .25s, opacity .25s;
        }

        &--active {
            color: $color_font-light;

            transition: color .25s;

            &:hover { opacity: 1; }
        }
    }

}
